import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import stores from './stores/index'
import Storage from 'vue-ls'

import '@/assets/font.css'
import moment from 'moment'

import { timeList } from '@/utils/time'
import { getTime } from '@/utils/global'
import { coolingTime } from '@/utils/coolingAddition'

//传入对应数字 返回对应时间段
Vue.prototype.$getTime = getTime
//传入时间段 返回x轴时间（ecahrts图表使用）
Vue.prototype.$timeList = timeList
Vue.prototype.$coolingTime = coolingTime

import defaultSettins from "@/defaultSettins"
Vue.use(Storage, defaultSettins.storageOptions)

Vue.prototype.moment = moment
import Antd, { version } from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd)



import './assets/common.css'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// // import style
// import 'swiper/swiper.less'
//
// Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

import Area from '@/common/util/Area.js'
Vue.prototype.$Area = new Area()
Vue.config.productionTip = false


new Vue({
  router,
  store,
  stores,
  render: h => h(App)
}).$mount('#app')
