<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getAllDictItems } from '@/api/home'

export default {

  created() {
    document.title = '智慧能源碳监测平台'

    // getAllDictItems().then(res => {
    //   try {
    //     if (res.code == 200 && res.result) {
    //       this.$ls.set('UI_CACHE_DB_DICT_DATA', res.result)
    //     }
    //   } catch (error) {

    //   }
    // })
  }

}
</script>

<style src="@/style/table.less" lang="less" scoped></style>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  scrollbar-width: 5px;
  /* Firefox */
  // -ms-overflow-style: none;

  // /* IE 10+ */
  // ::-webkit-scrollbar {
  //   display: none;
  //   /* Chrome Safari */
  // }

  font-family: SimHei;

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: #124967;

  }

  ::-webkit-scrollbar-track {
    background: #124967;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #31A5DC;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #49FFF1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #49FFF1;
    border-radius: 10px;
  }



  /* 父元素具有固定高度和水平滚动 */
  .scrollbar-container {
    height: 100px;
    overflow-x: scroll;
  }


  ::-webkit-scrollbar-thumb:horizontal {
    color: #333;
    box-shadow: 0 0 2px #ccc;
  }
}










html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: normal !important;

  .userPopover {
    .ant-popover-content {
      background: #4C4C4C;
      border-radius: 5px;

      .ant-popover-arrow {
        border-color: #4C4C4C;
      }
    }

    .ant-popover-inner {
      background: transparent;
    }
  }



  .errorUserPopover {
    width: 200px;

    .ant-popover-content {
      background: #0B4062;
      border-radius: 5px;

      .popoverContent {
        color: #fff;
        font-weight: 600;
      }

      .ant-popover-arrow {
        border-color: #0B4062;
      }
    }

    .ant-popover-title {
      border-bottom-color: #40B6F4;
    }

    .ant-popover-inner {
      background: transparent;
    }
  }

}




#app {
  width: 100%;
  height: 100%;
  min-width: 1450px !important;
  min-height: 800px !important;
}
</style>
