export function timeList(e, type) {
    let timeList = []
    //当传入类型为小时（天的数据）
    if (type == 'hour') {
        // 判断当前小时是否是两位数
        if (e[11] > 0) {
            let time = e[11] + e[12]
            let num = time - 1
            for (let i = 0; i <= Number(24 - time); i++) {
                num = Number(num) + 1
                if (num == 24) {
                    timeList.push('00')
                } else {
                    timeList.push(num)
                }
            }
            for (let i = 0; i < Number(12 + (time - 12)); i++) {
                if (String(i + 1).length == 1) {
                    timeList.push('0' + (i + 1))
                } else {
                    timeList.push(i + 1)
                }
            }
        } else {
            let time = e[11]+ e[12]
            let num = time -1
            for (let i = 0; i <= Number(24 - time); i++) {
                num = Number(num) + 1
                if (num == 24) {
                    timeList.push('00')
                } else {
                    if (String(num).length == 1) {
                        timeList.push('0' +num)
                    }else{
                        timeList.push( +num)

                    }
                }
            }
            for(let i = 1;i<=Number(time);i++){
                timeList.push('0' +i)
            }
        }
    }
    return timeList
}