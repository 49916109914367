import api from '@/api/http'

export function getAllDictItems() {
    return api.get('/sys/dict/queryAllDictItems')
}
//获取地图坐标和信息
export function getCoordinate() {
    return api.get('/largeScreen/buildCoordinate')
}
//获取监测次数
export function getTotalSummary() {
    return api.get('/undp/undpTotalSummary/queryTotalSummary')
}
//获取监测次数和右侧监测时长和建筑栋数
export function getBuildClassSumAvgByType(param) {
    return api.get('/undp/undpBuild/queryBuildClassSumAvgByType',param)
}
//获取一共有哪些建筑
export function getBuildNameList(param) {
    return api.get('/undp/undpBuild/list',param)
}

//获取建筑信息
export function getBuildNameAllList(param) {
    return api.get('/undp/undpBuild/dp/list',param)
}
//根据id获取地图坐标和信息
export function getCoordinateDetail(param) {
    return api.get('/undp/undpBuild/queryById',param)
}

//获取大屏能耗排名

export function getEnergyRanking(param) {
    return api.get('/undp/undpTotalSummary/dp/rank',param)
}

//获取建筑类型和数量
export function getBuildType() {
    return api.get('/undp/undpBuild/itemType/overviewSummary',)
}



export function getCarbon(param) {
    return api.get('/spt/tpfAppoint/carbon/proportion',param)
}

