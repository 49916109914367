export function coolingTime(e, type) {
    // console.log(e, type);
    let now = new Date()
    let time
    if (e == '当前时间') {
        const before = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        time = {
            startTime: `${before.getFullYear()}-${(before.getMonth() + 1).toString().padStart(2, '0')}-${before.getDate().toString().padStart(2, '0')} ${before.getHours().toString().padStart(2, '0')}:${before.getMinutes().toString().padStart(2, '0')}:${before.getSeconds().toString().padStart(2, '0')}`,
            endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`,
        }
    } else if (e == '今日') {
        time = {
            startTime: this.$getTime(2).startTime,
            endTime: this.$getTime(2).endTime,
        };
    } else if (e == '昨日') {
        const before = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        time = {
            startTime: `${before.getFullYear()}-${(before.getMonth() + 1).toString().padStart(2, '0')}-${before.getDate().toString().padStart(2, '0')} 00:00:00`,
            endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} 23:59:59`,
        };
    } else if (e == '本月') {
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
        time = {
            endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
            startTime: `${startOfMonth.getFullYear()}-${(startOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${startOfMonth.getDate().toString().padStart(2, '0')} 00:00:00`
        };
    } else if (e == '上月') {
        const lastMonthFirstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1); // 获取上个月的第一天
        const lastMonthLastDay = new Date(now.getFullYear(), now.getMonth(), 0); // 获取上个月的最后一天
        const firstDay = new Date(lastMonthFirstDay.getFullYear(), lastMonthFirstDay.getMonth(), 1); // 获取上个月第一天的日期对象
        const lastDay = new Date(lastMonthLastDay.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate(), 23, 59, 59); // 获取上个月最后一天的日期对象
        time = {
            startTime: `${firstDay.getFullYear()}-${(firstDay.getMonth() + 1).toString().padStart(2, '0')}-${firstDay.getDate().toString().padStart(2, '0')} 00:00:00`, // 格式化上个月第一天的日期对象为指定日期格式
            endTime: `${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().padStart(2, '0')}-${lastDay.getDate().toString().padStart(2, '0')} ${lastDay.getHours().toString().padStart(2, '0')}:${lastDay.getMinutes().toString().padStart(2, '0')}:${lastDay.getSeconds().toString().padStart(2, '0')}`, // 格式化上个月最后一天的日期对象为指定日期格式
        };
    } else if (e == '今年') {
        const firstDayOfYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
        time = {
            endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
            startTime: `${firstDayOfYear.getFullYear()}-01-01 00:00:00`
        };
    } else if (e == '上一年') {
        const lastYearFirstDay = new Date(now.getFullYear() - 1, 0, 1); // 获取上一年的第一天
        const lastYearLastDay = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59); // 获取上一年的最后一天
        
         time = {
          startTime: `${lastYearFirstDay.getFullYear()}-${(lastYearFirstDay.getMonth() + 1).toString().padStart(2, '0')}-${lastYearFirstDay.getDate().toString().padStart(2, '0')} 00:00:00`, // 格式化上一年的第一天的日期对象为指定日期格式
          endTime: `${lastYearLastDay.getFullYear()}-${(lastYearLastDay.getMonth() + 1).toString().padStart(2, '0')}-${lastYearLastDay.getDate().toString().padStart(2, '0')} ${lastYearLastDay.getHours().toString().padStart(2, '0')}:${lastYearLastDay.getMinutes().toString().padStart(2, '0')}:${lastYearLastDay.getSeconds().toString().padStart(2, '0')}`, // 格式化上一年的最后一天的日期对象为指定日期格式
        };
    } else if (e == '十二小时') {
        const before = new Date(now.getTime() - 12 * 60 * 60 * 1000);
        time = {
            startTime: `${before.getFullYear()}-${(before.getMonth() + 1).toString().padStart(2, '0')}-${before.getDate().toString().padStart(2, '0')} ${before.getHours().toString().padStart(2, '0')}:${before.getMinutes().toString().padStart(2, '0')}:${before.getSeconds().toString().padStart(2, '0')}`,
            endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`,
        };
    } else if (e == '本月') {
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
        time = {
            endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
            startTime: `${startOfMonth.getFullYear()}-${(startOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${startOfMonth.getDate().toString().padStart(2, '0')} 00:00:00`
        };
    }
    return time
}