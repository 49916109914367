//空气源热泵的当前小时  当前天  当前月  当前年（年为上一年的十一月到今年的五月  只有冬天供暖才使用空气源热泵）
// export function getTimeHeatPump(type) {
//   const now = new Date();
//   let time;
//   if (type === 1) {
//     const hourAgo = new Date(now.getTime() - 60 * 60 * 1000);
//     time = {
//       endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
//       startTime: `${hourAgo.getFullYear()}-${(hourAgo.getMonth() + 1).toString().padStart(2, '0')}-${hourAgo.getDate().toString().padStart(2, '0')} ${hourAgo.getHours().toString().padStart(2, '0')}:${hourAgo.getMinutes().toString().padStart(2, '0')}:${hourAgo.getSeconds().toString().padStart(2, '0')}`
//     };
//   } else if (type === 2) {
//     const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
//     time = {
//       endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
//       startTime: `${todayStart.getFullYear()}-${(todayStart.getMonth() + 1).toString().padStart(2, '0')}-${todayStart.getDate().toString().padStart(2, '0')} 00:00:00`
//     };
//   } else if (type === 3) {
//     const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
//     time = {
//       endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
//       startTime: `${startOfMonth.getFullYear()}-${(startOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${startOfMonth.getDate().toString().padStart(2, '0')} 00:00:00`
//     };
//   } else if (type === 4) {
//     // 返回去年的十一月份和今年的五月份的时间
//     const lastYearNovember = new Date(now.getFullYear() - 1, 10, 1, 0, 0, 0); // 10 表示十一月
//     const thisYearMay = new Date(now.getFullYear(), 4, 1, 0, 0, 0); // 4 表示五月
//     const lastYearNovemberStr = `${lastYearNovember.getFullYear()}-11-01 00:00:00`;
//     const thisYearMayStr = `${thisYearMay.getFullYear()}-05-01 00:00:00`;
//     time = {
//       endTime: lastYearNovemberStr,
//       startTime: thisYearMayStr
//     };
//   }
//   else {
//     time = now;
//   }
//   return time;
// }







export function getTime(type) {
  //分别获取当前小时  当前天  当前月  当前年
  const now = new Date();
  let time;
  if (type === 1) {
    const hourAgo = new Date(now.getTime() - 60 * 60 * 1000);

    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${hourAgo.getFullYear()}-${(hourAgo.getMonth() + 1).toString().padStart(2, '0')}-${hourAgo.getDate().toString().padStart(2, '0')} ${hourAgo.getHours().toString().padStart(2, '0')}:${hourAgo.getMinutes().toString().padStart(2, '0')}:${hourAgo.getSeconds().toString().padStart(2, '0')}`
    };
  } else if (type === 2) {
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${todayStart.getFullYear()}-${(todayStart.getMonth() + 1).toString().padStart(2, '0')}-${todayStart.getDate().toString().padStart(2, '0')} 00:00:00`
    };
  } else if (type === 3) {
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${startOfMonth.getFullYear()}-${(startOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${startOfMonth.getDate().toString().padStart(2, '0')} 00:00:00`
    };
  } else if (type === 4) {
    const now = new Date();
    const firstDayOfYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${firstDayOfYear.getFullYear()}-01-01 00:00:00`
    };
  }
  // 获取当前时间向前推5分钟
  else if (type === 5) {
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);
    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${fiveMinutesAgo.getFullYear()}-${(fiveMinutesAgo.getMonth() + 1).toString().padStart(2, '0')}-${fiveMinutesAgo.getDate().toString().padStart(2, '0')} ${fiveMinutesAgo.getHours().toString().padStart(2, '0')}:${fiveMinutesAgo.getMinutes().toString().padStart(2, '0')}:${fiveMinutesAgo.getSeconds().toString().padStart(2, '0')}`
    };
  }
  // 获取当前时间向前推24小时
  else if (type === 6) {
    const before24h = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${before24h.getFullYear()}-${(before24h.getMonth() + 1).toString().padStart(2, '0')}-${before24h.getDate().toString().padStart(2, '0')} ${before24h.toLocaleTimeString('en-US', { hour12: false })}`
    };
  }

  //  获取当前时间向前推十二个月
  if (type === 7) {
    const hourAgo = new Date(now.getTime() - 60 * 60 * 24 * 365 * 1000);

    time = {
      endTime: `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${hourAgo.getFullYear()}-${(hourAgo.getMonth() + 1).toString().padStart(2, '0')}-${hourAgo.getDate().toString().padStart(2, '0')} ${hourAgo.getHours().toString().padStart(2, '0')}:${hourAgo.getMinutes().toString().padStart(2, '0')}:${hourAgo.getSeconds().toString().padStart(2, '0')}`
    };
  }

  //分别获取上一年的当前小时  当前天  当前月  当前年
  else if (type === 10) {
    const now = new Date();
    const endTime = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
    const startTime = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), now.getHours() - 1, now.getMinutes(), now.getSeconds());
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    time = {
      startTime: startTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
      endTime: endTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
    };
  } else if (type === 20) {
    const now = new Date();
    const startTime = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), 0, 0, 0);
    const endTime = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    time = {
      startTime: startTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
      endTime: endTime.toLocaleString('zh-CN', options).replace(/\//g, '-')
    };
  } else if (type === 30) {
    const now = new Date();
    const startTime = new Date(now.getFullYear() - 1, now.getMonth(), 1, 0, 0, 0);
    const endTime = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    time = {
      startTime: startTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
      endTime: endTime.toLocaleString('zh-CN', options).replace(/\//g, '-')
    };
  } else if (type === 40) {
    const now = new Date();
    const firstDayOfYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    time = {
      endTime: `${now.getFullYear() - 1}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
      startTime: `${firstDayOfYear.getFullYear() - 1}-01-01 00:00:00`
    };
  }

  // // 分别获取前年的当前小时  当前天  当前月  当前年
  // if (type === 100) {
  //   const hourAgo = new Date(now.getTime() - 60 * 60 * 1000);
  //   time = {
  //     endTime: `${now.getFullYear()-1}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
  //     startTime: `${hourAgo.getFullYear()-1}-${(hourAgo.getMonth() + 1).toString().padStart(2, '0')}-${hourAgo.getDate().toString().padStart(2, '0')} ${hourAgo.getHours().toString().padStart(2, '0')}:${hourAgo.getMinutes().toString().padStart(2, '0')}:${hourAgo.getSeconds().toString().padStart(2, '0')}`
  //   };
  // } else if (type === 200) {
  //   const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  //   time = {
  //     endTime: `${now.getFullYear()-1}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
  //     startTime: `${todayStart.getFullYear()-1}-${(todayStart.getMonth() + 1).toString().padStart(2, '0')}-${todayStart.getDate().toString().padStart(2, '0')} 00:00:00`
  //   };
  // } else if (type === 300) {
  //   const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
  //   time = {
  //     endTime: `${now.getFullYear()-1}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
  //     startTime: `${startOfMonth.getFullYear()-1}-${(startOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${startOfMonth.getDate().toString().padStart(2, '0')} 00:00:00`
  //   };
  // } else if (type === 400) {
  //   const now = new Date();
  //   const firstDayOfYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  //   time = {
  //     endTime: `${now.getFullYear() - 1}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
  //     startTime: `${firstDayOfYear.getFullYear() - 1}-01-01 00:00:00`
  //   };
  // }

  // //分别获取大前年的当前小时  当前天  当前月  当前年
  // else if (type === 1000) {
  //   const now = new Date();
  //   const endTime = new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
  //   const startTime = new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), now.getHours() - 1, now.getMinutes(), now.getSeconds());
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  //   time = {
  //     startTime: startTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
  //     endTime: endTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
  //   };
  // } else if (type === 2000) {
  //   const now = new Date();
  //   const startTime = new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), 0, 0, 0);
  //   const endTime = new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  //   time = {
  //     startTime: startTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
  //     endTime: endTime.toLocaleString('zh-CN', options).replace(/\//g, '-')
  //   };
  // } else if (type === 3000) {
  //   const now = new Date();
  //   const startTime = new Date(now.getFullYear() - 2, now.getMonth(), 1, 0, 0, 0);
  //   const endTime = new Date(now.getFullYear() - 2, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  //   time = {
  //     startTime: startTime.toLocaleString('zh-CN', options).replace(/\//g, '-'),
  //     endTime: endTime.toLocaleString('zh-CN', options).replace(/\//g, '-')
  //   };
  // } else if (type === 4000) {
  //   const now = new Date();
  //   const firstDayOfYear = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
  //   const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  //   time = {
  //     endTime: `${now.getFullYear() - 2}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.toLocaleTimeString('en-US', { hour12: false })}`,
  //     startTime: `${firstDayOfYear.getFullYear() - 2}-01-01 00:00:00`
  //   };
  // }

  // else {
  //   time = now;
  // }
  return time;
}



